import React from "react";
import Home from "./pages/Home";
import RemovingNewsOrAnyOtherLinks from "./pages/RemovingNewsOrAnyOtherLinks";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RemovingReviews from "./pages/RemovingReviews";
import DeletingInformation from "./pages/DeletingInformation";
import RemovingGlassdoor from "./pages/RemovingGlassdoor";
import Blog from "./pages/Blog";
import DeletingVideosFromYoutubeTikTok from "./pages/DeletingVideosFromYoutubeTikTok";
import ReputationManagement from "./pages/ReputationManagement";
import ReputationMonitoring from "./pages/ReputationMonitoring";
import ReputationProtecting from "./pages/ReputationProtecting";
import ReputationImproving from "./pages/ReputationImproving";
import ReputationManagementMistakesToAvoid from "./pages/ReputationManagementMistakesToAvoid";
import HowToRemovePostsAboutYourselfOnReddit from "./pages/HowToRemovePostsAboutYourselfOnReddit";
import HowToDeleteGoogleResultsWhatIsDeindex from "./pages/HowToDeleteGoogleResultsWhatIsDeindex";
import GlassdoorReputationManagementRemoveGlassdoorReviews from "./pages/GlassdoorReputationManagementRemoveGlassdoorReviews";
import ImageRemovalFromGoogleHowToTakeDownYourPhotos from "./pages/ImageRemovalFromGoogleHowToTakeDownYourPhotos";
import CleaningUpYourDigitalFootprint from "./pages/CleaningUpYourDigitalFootprint";
import HowToRestoreCustomerTrustAfterAPrCrisis from "./pages/HowToRestoreCustomerTrustAfterAPrCrisis";
import CombattingFakeNewsToProtectYourReputation from "./pages/CombattingFakeNewsToProtectYourReputation";
import FourFactorsThatImpactBrandReputation from "./pages/FourFactorsThatImpactBrandReputation";
import HowToManageYourOnlineReputation from "./pages/HowToManageYourOnlineReputation";
import HowToRemoveReviewsFromGoogleMyBusinessCard from "./pages/HowToRemoveReviewsFromGoogleMyBusinessCard";
import HowToRemoveYourNameFromBackgroundChecks from "./pages/HowToRemoveYourNameFromBackgroundChecks";
import HowToSpotFakeReviewsOnline from "./pages/HowToSpotFakeReviewsOnline";
import InfluencerPartnershipsRisksToReputation from "./pages/InfluencerPartnershipsRisksToReputation";
import TheImportanceOfMonitoringYourOnlineReputation from "./pages/TheImportanceOfMonitoringYourOnlineReputation";


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services/removing-news-or-any-other-links-from-google-search" element={<RemovingNewsOrAnyOtherLinks />} />
        <Route path="/services/removing-rewiews-from-the-google-my-business-card" element={<RemovingReviews />} />
        <Route path="/services/removing-reviews-from-glassdoor-tripadvisor-yelp" element={<RemovingGlassdoor />} />
        <Route path="/services/deleting-personal-information-from-the-internet" element={<DeletingInformation />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/services/deleting-videos-from-youtube-tiktok" element={<DeletingVideosFromYoutubeTikTok />} />
        <Route path="/services/reputation-management" element={<ReputationManagement />} />
        <Route path="/services/reputation-monitoring" element={<ReputationMonitoring />} />
        <Route path="/services/reputation-protecting" element={<ReputationProtecting />} />
        <Route path="/services/reputation-improving" element={<ReputationImproving />} />
        <Route path="/blog/5-reputation-management-mistakes-to-avoid" element={<ReputationManagementMistakesToAvoid />} />
        <Route path="/blog/how-to-remove-posts-about-yourself-on-reddit" element={<HowToRemovePostsAboutYourselfOnReddit />} />
        <Route path="/blog/how-to-delete-google-results-what-is-deindex" element={<HowToDeleteGoogleResultsWhatIsDeindex />} />
        <Route path="/blog/glassdoor-reputation-management-remove-glassdoor-reviews" element={<GlassdoorReputationManagementRemoveGlassdoorReviews />} />
        <Route path="/blog/image-removal-from-google-how-to-take-down-your-photos" element={<ImageRemovalFromGoogleHowToTakeDownYourPhotos />} />
        <Route path="/blog/cleaning-up-your-digital-footprint" element={<CleaningUpYourDigitalFootprint />} />
        <Route path="/blog/how-to-remove-your-name-from-background-checks" element={<HowToRemoveYourNameFromBackgroundChecks />} />
        <Route path="/blog/how-to-restore-customer-trust-after-a-pr-crisis" element={<HowToRestoreCustomerTrustAfterAPrCrisis />} />
        <Route path="/blog/the-importance-of-monitoring-your-online-reputation" element={<TheImportanceOfMonitoringYourOnlineReputation />} />
        <Route path="/blog/influencer-partnerships-risks-to-reputation" element={<InfluencerPartnershipsRisksToReputation />} />
        <Route path="/blog/4-factors-that-impact-brand-reputation" element={<FourFactorsThatImpactBrandReputation />} />
        <Route path="/blog/combatting-fake-news-to-protect-your-reputation" element={<CombattingFakeNewsToProtectYourReputation />} />
        <Route path="/blog/how-to-spot-fake-reviews-online" element={<HowToSpotFakeReviewsOnline />} />
        <Route path="/blog/how-to-remove-reviews-from-google-my-business-card" element={<HowToRemoveReviewsFromGoogleMyBusinessCard />} />
        <Route path="/blog/how-to-manage-your-online-reputation" element={<HowToManageYourOnlineReputation />} />
      </Routes>
    </div>
  );
}

export default App;