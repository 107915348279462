import React from "react";
import "../App.css";
import Main from "../components/Main";
import { Quiz } from "../components/Quiz";
import { QuizForm } from "../components/QuizForm";
import { Process } from "../components/Process";
import { Guarantees } from "../components/Guarantees";
import Footer from "../components/Footer";
import { ModalsContainer } from "../components/ModalsContainer";
import { RealFooter } from "../components/RealFooter";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function ImageRemovalFromGoogleHowToTakeDownYourPhotos() {
  return (
    <div className="App">
      <HelmetProvider>
        <Helmet>
          <title>Image Removal From Google: How To Take Down Your Photos | Reputation Coalition</title>
          <meta name="description" content="Discover how to remove images from Google easily with Reputation Coalition. This guide provides step-by-step instructions how to take down your photos and pictures from search results, ensuring your online privacy." />
          <meta name="keywords" content="how to remove images from google, delete photo, picture, search" />
        </Helmet>
      </HelmetProvider>
    
      <Main 
        heading="Removing reviews from Glassdoor, TripAdvisor, Yelp" text="We can help you improve your reputation by removing negative reviews from Glassdoor, TripAdvisor, and Yelp. At Reputation Coalition, we specialize in managing and enhancing your online image by deleting harmful reviews that can negatively impact your business. Whether you're facing unfair feedback, false accusations, or outdated comments, our expert team is here to assist you in protecting your brand’s reputation across these influential platforms."></Main>
      
      <Quiz>
        <QuizForm></QuizForm>
      </Quiz>
      <Process></Process>
      <Guarantees></Guarantees>

      <>
        <section className=" py-12 " id="text">
          <div className="container">
            <div className="section_content text-white text-lg section_guarantees	text-z">
                <h1 className="mb-5"><strong>Image Removal From Google: How To Take Down Your Photos</strong></h1>
                
            </div>
          </div>
        </section>
      </>

      <Footer></Footer>
      <ModalsContainer></ModalsContainer>
      <RealFooter></RealFooter>
    </div>
  );
}

export default ImageRemovalFromGoogleHowToTakeDownYourPhotos;
